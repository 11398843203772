import { loadScript } from "@paypal/paypal-js"

export default {
	install(app, options = {}) {
		// options.clientId = options.clientId ?? "AYuQn66xxIT0BxaIpl85E4P8GzsQ_SlR3GalsBr76f8atWRfELRE5-e_mbsw38LtoFvP1IiTNPP_dNcl"
		options.clientId = options.clientId ?? "AYvYkVgFHueVDqisCBvhz874HTJeNt2WkGKDMgveSckVAdTm9QWPxOpqgSATiEZw2WXWrj9gYGcq0ShY"
		options.components = options.components ?? "buttons,funding-eligibility,messages"
		options.currency = options.currency ?? 'USD'

		let instance
		let semaphore = false

		const getInstance = async () => {
			if (!instance && !semaphore) {
				semaphore = true
				instance = await loadScript({
					clientId: options.clientId,
					components: options.components,
					currency: options.currency,
				})
			}

			return instance
		}

		app.config.globalProperties.$loadPaypal = getInstance
	}
}